import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const search = css`
  min-width: ${spacing(580)};
`;

export const searchFilter = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const datePickers = css`
  display: flex;
  margin-bottom: ${spacing(32)};

  & > div {
    margin-right: ${spacing(32)};
  }
`;

export const results = css`
  margin-bottom: ${spacing(32)};
  font-family: var(--font-size-small);
`;

export const table = css`
  overflow: auto;
  min-width: 1400px;
`;

export const loadingSpace = css`
  padding: ${spacing(32)} 0 ${spacing(32)};
`;

export const tableHead = css`
  td {
    color: hsl(var(--color-textLight-7));
    font-size: var(--font-size-micro);
    letter-spacing: ${spacing(1.15)};
    text-transform: uppercase;
    padding: 0 0 ${spacing(16)} ${spacing(0)};
  }
`;

export const textRight = css`
  text-align: right;
`;

export const textSmall = css`
  font-size: var(--font-size-small);
`;

export const tr = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  transition: 0.2s ease;

  &:hover {
    background: hsl(var(--color-backgroundLight-10));
  }

  td {
    padding: ${spacing(16)} 0;
  }
`;

export const tdFaded = css`
  color: hsl(var(--color-borderDark-5));
  font-size: var(--font-size-small);
`;

export const tagSuccess = css`
  div {
    background: hsl(var(--color-textSuccess-7));
    color: #ffffff;
    pointer-events: none;
    text-transform: capitalize;
  }
`;

export const tagWarning = css`
  div {
    background: hsl(var(--color-note-8));
    color: #ffffff;
    pointer-events: none;
    text-transform: capitalize;
  }
`;

export const tagError = css`
  div {
    background: hsl(var(--color-warning-6));
    color: #ffffff;
    pointer-events: none;
    text-transform: capitalize;
  }
`;

export const sortByDropdown = css`
  font-weight: var(--font-weight-regular);
  padding: 0;
`;

export const pagination = css`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
  }

  ul {
    width: 100%;
  }

  .previous {
    margin-right: auto;
  }

  .next {
    margin-left: auto;
  }
`;

export const loading = css`
  opacity: 0.4;
  transition: 0.2s;
  pointer-events: none;
`;

export const skeleton = css`
  min-height: ${spacing(50)};
  margin-bottom: ${spacing(16)};
`;

export const plans = css`
  padding-left: ${spacing(32)};
`;

export const plansTitle = css`
  margin-bottom: ${spacing(40)};
`;
